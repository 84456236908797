// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../utils/Url.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Page from "../../../../routes/common/Routes_Page.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as ProjectNetworkTypeRadioTile from "../_components/ProjectNetworkTypeRadioTile/ProjectNetworkTypeRadioTile.res.js";
import * as CreateNetworkProjectNetworkTypeSelectionScss from "./CreateNetworkProjectNetworkTypeSelection.scss";

var css = CreateNetworkProjectNetworkTypeSelectionScss;

function header(network) {
  switch (network) {
    case "SdWan" :
        return "SD-WAN";
    case "PrivateLine" :
        return "Private Line";
    case "Mpls" :
        return "MPLS Network";
    
  }
}

function description(network) {
  switch (network) {
    case "SdWan" :
        return "Start your network project for Software Defined WAN (SD-WAN).";
    case "PrivateLine" :
        return "Start your network project for Private Line, Point to Point Services.";
    case "Mpls" :
        return "Start your network project for Multi-Protocol Label Switching (MPLS).";
    
  }
}

var NetworkType = {
  header: header,
  description: description
};

function CreateNetworkProjectNetworkTypeSelection(props) {
  var initialState = React.useMemo((function () {
          return {
                  selectedNetworkType: undefined
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    selectedNetworkType: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var navigationPreviousControl_1 = function (param) {
    Url.visit(Routes_Project.index);
  };
  var navigationPreviousControl = [
    "Cancel",
    navigationPreviousControl_1,
    "Enabled"
  ];
  var navigationNextControl = React.useMemo((function () {
          var match = state.selectedNetworkType;
          return [
                  "Next Step",
                  (function () {
                      var networkType = state.selectedNetworkType;
                      if (networkType !== undefined) {
                        return Url.visit(Routes_Project.Network.Configuration.$$new(networkType));
                      }
                      
                    }),
                  match !== undefined ? "Enabled" : "Disabled"
                ];
        }), [state.selectedNetworkType]);
  var networkType = state.selectedNetworkType;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h1", {
                                    children: "Network RFP for Private Line, SD-WAN and MPLS",
                                    className: css.title
                                  }),
                              className: css.tooltipAndHeadingWrapper
                            }),
                        Belt_Array.map(props.networkTypes, (function (networkType) {
                                var selectedNetwork = state.selectedNetworkType;
                                return JsxRuntime.jsx(ProjectNetworkTypeRadioTile.make, {
                                            networkType: networkType,
                                            checked: selectedNetwork !== undefined ? networkType === selectedNetwork : false,
                                            onClick: (function (param) {
                                                dispatch({
                                                      TAG: "SelectNetworkType",
                                                      _0: networkType
                                                    });
                                              })
                                          }, Project.Category.toString({
                                                TAG: "Network",
                                                _0: networkType
                                              }));
                              })),
                        networkType !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("h3", {
                                        children: header(networkType),
                                        className: css.networkHeading
                                      }),
                                  JsxRuntime.jsx("p", {
                                        children: description(networkType),
                                        className: css.networkDescription
                                      })
                                ]
                              }) : JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsxs("p", {
                                        children: [
                                          "Searching for a quick and easy way to create and distribute your network RFP to top network service providers? Datacenters.com provides a digital RFP template for popular wide area network services including ethernet ",
                                          JsxRuntime.jsx("a", {
                                                children: "private line ",
                                                href: Routes_Project.Network.PrivateLine.$$new
                                              }),
                                          "and point to point services, ",
                                          JsxRuntime.jsx("a", {
                                                children: "SD-WAN",
                                                href: Routes_Project.Network.SdWan.$$new
                                              }),
                                          ", and ",
                                          JsxRuntime.jsx("a", {
                                                children: "MPLS network services",
                                                href: Routes_Project.Network.Mpls.$$new
                                              }),
                                          ". Want to compare WAN solutions from multiple ",
                                          JsxRuntime.jsx("a", {
                                                children: "network service providers",
                                                href: Routes_Provider.network
                                              }),
                                          ", ",
                                          JsxRuntime.jsx("a", {
                                                children: "ISPs",
                                                href: Routes_Provider.internet
                                              }),
                                          ", and telecom carriers? We offer access to all major network and telecommunications providers globally."
                                        ],
                                        className: css.networkDescription
                                      }),
                                  JsxRuntime.jsxs("p", {
                                        children: [
                                          "How exactly does it work? We've created pre-defined RFP questions that ",
                                          JsxRuntime.jsx("a", {
                                                children: "network providers",
                                                href: Routes_Provider.network
                                              }),
                                          " ask in order to process network pricing and quote requests. This includes questions about your organization, locations, technology preferences, important features, and capabilities. When you answer the questions, our matching algorithmautomatically selects the best network service providers based on your requirements."
                                        ],
                                        className: css.networkDescription
                                      }),
                                  JsxRuntime.jsx("p", {
                                        children: "Once matched, you can select all or deselect providers so that you receive RFP responses only from the providers that you are interested in working with. The last step of the process is to sign-up on Datacenters.com and provide contact information, project details, and any related documents.",
                                        className: css.networkDescription
                                      }),
                                  JsxRuntime.jsx("p", {
                                        children: "Your network RFP including all information and details will be sent directly to the network providers you selected. They will receive your network RFP information and respond directly in the Datacenters.com RFP Platform. You will receive email alerts providers respond and you can view your network RFP responses at any\n            time.",
                                        className: css.networkDescription
                                      }),
                                  JsxRuntime.jsxs("p", {
                                        children: [
                                          "Need help creating your network RFP? ",
                                          JsxRuntime.jsx("a", {
                                                children: "Datacenters.com",
                                                href: "/"
                                              }),
                                          " offers free network design and consulting services. This includes creating an inventory and assessment of your current wide area network (WAN) as well as making technology and network architecture recommendations to\n             increase performance and security. ",
                                          JsxRuntime.jsx("a", {
                                                children: "Contact us",
                                                href: Routes_Page.contact
                                              }),
                                          " now to speak with a Datacenters.com Network Engineer."
                                        ],
                                        className: css.networkDescription
                                      })
                                ],
                                className: css.networkTextContainer
                              })
                      ],
                      className: css.contentWrapper
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Select Category",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: navigationNextControl,
                        previous: navigationPreviousControl,
                        continueLater: undefined
                      },
                      mobile: false
                    })
              ],
              className: css.container
            });
}

var make = CreateNetworkProjectNetworkTypeSelection;

export {
  css ,
  NetworkType ,
  make ,
}
/* css Not a pure module */
