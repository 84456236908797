// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as RadioButton from "../../../../../styleguide/forms/RadioButton/RadioButton.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectNetworkTypeRadioTileScss from "./ProjectNetworkTypeRadioTile.scss";

var css = ProjectNetworkTypeRadioTileScss;

function label(network) {
  switch (network) {
    case "SdWan" :
        return "SD-WAN";
    case "PrivateLine" :
        return "Private Line";
    case "Mpls" :
        return "MPLS Network";
    
  }
}

function subHeading(network) {
  switch (network) {
    case "SdWan" :
        return "Start your network project for Software Defined WAN (SD-WAN).";
    case "PrivateLine" :
        return "Start your network project for Private Line, Point to Point Services.";
    case "Mpls" :
        return "Start your network project for Multi-Protocol Label Switching (MPLS).";
    
  }
}

function ProjectNetworkTypeRadioTile(props) {
  var __className = props.className;
  var checked = props.checked;
  var networkType = props.networkType;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(RadioButton.make, {
                      id: label(networkType),
                      checked: checked,
                      onChange: (function (prim) {
                          
                        })
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: label(networkType),
                              className: css.networkLabel
                            }),
                        JsxRuntime.jsx("p", {
                              children: subHeading(networkType),
                              className: css.networkSubHeading
                            })
                      ],
                      className: css.headingAndDescriptionWrapper
                    })
              ],
              className: Cx.cx([
                    css.wrapper,
                    checked ? css.borderChecked : css.borderUnchecked,
                    className
                  ]),
              onClick: props.onClick
            });
}

var make = ProjectNetworkTypeRadioTile;

export {
  css ,
  label ,
  subHeading ,
  make ,
}
/* css Not a pure module */
